.nav-tabs {
  border-bottom: none;
}

.nav-tabs>li {
  font-weight: bold;
  border-right: 1px solid #757D88;
  margin-bottom: 0;
  width: 150px;
  text-align: center;
}


.nav-tabs>li>a {
  color: #727A85;
  margin-right: 0;
}

.nav>a.disabled {
  color: #464E59;
}

.nav-tabs>>a {
  border-radius:0;
  cursor: pointer;
}

.nav-tabs>a:hover, .nav-tabs>a.active, .nav-tabs>a.active:hover, .nav-tabs>a:active, .nav-tabs>a.active:focus {
  color: #66A6B9;
}

a.nav-link.disabled {
  color: #464E59 !important; /*#6B737E; */
}

.left-nav div.panel {
  width: 250px;
  margin-left: -15px;
  border-radius: 0;
  background-color: #399FD0;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.left-nav div.panel-heading {
  background-color: #399FD0;
  border-radius: 0;
  border-left: none;
  border-right: none;
  border-top: 1px solid #40A3DA;
  border-bottom: 1px solid #2F95C6;
}

.left-nav .panel-title a span {

  font-size:  14px;
  font-weight: bold;
  color:  #fff;

}

.modal-md {
  width: 450px !important;
  margin: 50px auto 0;
}

.modal-dialog {
  margin-top: 80px !important;
/*  width: auto; */
}

.popup {
  z-index: 1000;
}

.modal-content {
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 0 auto;
/*  width: 800px; */
}

