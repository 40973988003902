html, body {
    height: 100%;
    background-color: #F3F3F3;
}

label[required]::after {
  margin-left: 2px;
  content: '*';
  color: #e20000;
}

table tr {
  border-bottom: 1px solid #ccc;
}

table tr:last-child {
  border-bottom: none;
}

.feedCalendarModal .modal-content {
  width: 530px !important;
}

input.ng-valid[required], select.ng-invalid, textarea.ng-invalid {
  /* border-left: 5px solid #42A948; green */
}

input.ng-invalid:not(.ng-pristine), select.ng-invalid:not(.ng-pristine), textarea.ng-invalid:not(.ng-pristine) {
  border-left: 5px solid #a94442; /* red */
}

a.disabled {
   pointer-events: none;
   cursor: default;
/*   color: #d3d3d3 !important; */
}

.header {
  padding: 5px;
  background-color: #EFF3F6;
}

.custom-modal-container {
    padding: 15px;
}

.custom-modal-header {
    background-color: #428BCA;
    color: #fff;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.75);
    margin-top: -15px;
    margin-bottom: 40px;
}

.af-inline-icon::before {
  font-family: FontAwesome;
  content: attr(icon);
  width:12px;
  margin-right: 7px;
  text-align:center;
  display:inline-block;
}

.content_main {
  width:  auto !important;
  clear: both;
  margin-left: 250px !important;
  margin-top: 0px !important;
  float: none !important;
}

@media(max-width: 1199px) {
  .content_main {
    margin-left: 50px !important;
  }
}

.button_container {float:right;}

.loading-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 50000;
  background: rgba(255, 255, 255, 0.5);
}
.loading-indicator {
  z-index: 50001;
  position: fixed;
  width: 80px;
  height: 80px;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  /* background: url('/app/images/loading_ring.gif') center center no-repeat; */
  text-align: center;
}
.loading-indicator img {
  margin-top: calc(50% - 30px);
  max-width: 60px;
  max-height: 60px;
  opacity: 0.75;
}



/*NEW STYLES*/
/* Sppiner Css */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 84px;
  height: 84px;
  margin: 8px;
  border: 8px solid #0024ff8a;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0024ff8a transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.single-form {
  max-width: 800px;
  margin: 0 auto;
}

.modal-form {
  padding-left: 15px;
  padding-right: 15px;
}

form.form-horizontal label {
  font-weight: normal;
}

img.form-profile-pic {
  max-width: 100px;
  max-height: 100px;
}

h4.form-heading {
  padding-top: 5px;
  font-size: 23px;
  font-weight: normal;
  color: rgb(61, 98, 120);
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

div.checkbox-field {
  padding-top: 5px;
}

a.image-edit-link {
  margin-left: -20px; position:absolute; bottom: 0;
}

.mobile_show .menu-list a {
  display: block !important;
}
